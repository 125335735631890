import React from 'react'
import 'twin.macro'

export default function ServicesDetail({data,page}){
    let finalData= data?.schema_json;  
    let content= {
        heading:"All-in-one platform",
        sub_heading:"hahah",
        description:"",
        cards:[
            {
                heading:"Invite team members",
                description:"Tempor tellus in aliquet eu et sit nulla tellus. Suspendisse est, molestie blandit quis ac. Lacus."
            },
            {
                heading:"Notifications",
                description:"Ornare donec rhoncus vitae nisl velit, neque, mauris dictum duis. Nibh urna non parturient.",
            },
            {
                heading:"List view",
                description:"Etiam cras augue ornare pretium sit malesuada morbi orci, venenatis. Dictum lacus.",
            },
            {
                heading:"Notifications",
                description:"Ornare donec rhoncus vitae nisl velit, neque, mauris dictum duis. Nibh urna non parturient.",
            }
        ],
       ...finalData
  }

  let cardsData = content.cards.map((card, i) => (
    <div tw="flex flex-col justify-center items-center"  key={i}>
        <svg tw="flex-shrink-0 h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
        </svg>
        <div tw="mt-2 text-lg text-center leading-6 font-medium text-gray-900">
            {card.heading}
        </div>
        <div tw="mt-2 text-base text-gray-500 text-center">
            {card.description}
        </div>
    </div>
    ))

    return (
        <div tw="bg-white w-full">
            <div tw="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:py-6 lg:px-8 mb-6">
                <div tw="max-w-3xl mx-auto text-center">
                <h3 tw="text-xl font-semibold text-gray-900">{content.sub_heading}</h3>
                <h2 tw="text-3xl font-extrabold text-gray-900">{content.heading}</h2>
                <p tw="mt-4 text-lg text-gray-500">{content.description}</p>
                </div>
                <dl tw="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-3 lg:gap-x-12">
                    {cardsData}
                </dl>
            </div>
            </div>

    )
}
